export const CUSTOMER_MARKUP_RATE_DECIMAL = 0.1;
/**
 * @note this is actually a decimal, update function and function calls
 */
export const CUSTOMER_FEE_RATE_DECIMAL = 0.2;
export const COACH_FEE_CENTS = 0; // In cents
export const CREDIT_CARD_FEE_RATE_DECIMAL = 0.05;

interface Params {
  priceUnitAmount: number;
  customerFeeCents?: number;
  coachFeeCents?: number;
  creditCardFeeRateDecimal?: number;
}

// NOTE: Do we need to do anymore to ensure we are only working with integers?
// We should be fine if we round all multiplication if we can trust priceUnitAmount
export const getVideoAnalysisItemizedTotal = ({
  priceUnitAmount,
  customerFeeCents = CUSTOMER_FEE_RATE_DECIMAL,
  coachFeeCents = COACH_FEE_CENTS,
  creditCardFeeRateDecimal = CREDIT_CARD_FEE_RATE_DECIMAL,
}: Params) => {
  const orderSubtotalUnitAmount = priceUnitAmount; // Sum of items

  // Fees
  const customerApplicationFeeUnitAmount = Math.ceil(orderSubtotalUnitAmount * customerFeeCents);
  const sellerApplicationFeeUnitAmount = Math.ceil(coachFeeCents); // Note: Paid by the coach (indirectly) after the transaction is complete
  const orderSubtotalWithPlatformFeesUnitAmount =
    orderSubtotalUnitAmount + customerApplicationFeeUnitAmount;
  const creditCardFeeUnitAmount = Math.ceil(orderSubtotalUnitAmount * creditCardFeeRateDecimal);

  /**
   * @note (Fees Bounce keeps) - Application fee is the Stripe terminology for the platform service fee that Bounce receives
   */
  const applicationFeeTotal =
    customerApplicationFeeUnitAmount + sellerApplicationFeeUnitAmount + creditCardFeeUnitAmount;

  /**
   * @note (What the customer pays) - Seller fee is not in the order total. This total is what is paid by the customer. The coach's fee is deducted after money has been received.
   */
  const orderTotalUnitAmount = orderSubtotalWithPlatformFeesUnitAmount + creditCardFeeUnitAmount;

  /**
   * @note The coach receives the payment price minus the additional service fee from customer and the fee paid to us by the coach.
   */
  const coachAmountReceived = orderTotalUnitAmount - applicationFeeTotal;

  return {
    customerApplicationFee: customerApplicationFeeUnitAmount,
    sellerApplicationFee: sellerApplicationFeeUnitAmount,
    creditCardFee: creditCardFeeUnitAmount,
    orderSubtotal: orderSubtotalUnitAmount,
    orderTotal: orderTotalUnitAmount,
    orderSubtotalWithPlatformFeesUnitAmount,
    coachAmountReceived,
    applicationFeeTotal,
    // input
    priceUnitAmount,
    customerFeeCents,
    coachFeeCents,
    creditCardFeeRateDecimal,
  };
};
