import { addMinutes, isWithinInterval, parseISO, subMinutes } from 'date-fns';

export const mergeDateAndSlot = (date: Date, slot: string): Date => {
  const [time, modifier] = slot.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (modifier.toLowerCase() === 'pm' && hours < 12) {
    hours += 12;
  } else if (modifier.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }

  const mergedDate = new Date(date);
  mergedDate.setHours(hours, minutes, 0, 0);

  return mergedDate;
};

export const isSlotOverlapping = (mergedDate: Date, bookedArray: any): boolean => {
  return bookedArray?.some((booked: any) => {
    const start = parseISO(booked.startDateTime);
    const end = addMinutes(start, 60);
    return isWithinInterval(mergedDate, {
      start: subMinutes(start, 60),
      end: end,
    });
  });
};
