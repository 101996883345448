import * as React from 'react';

export default function ArrowLeft({
  className,
  viewBox = '0 0 16 16',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.1653 11.7657C5.85288 12.0781 5.34634 12.0781 5.03393 11.7657L1.83392 8.56569C1.5215 8.25327 1.5215 7.74673 1.83392 7.43432L5.03393 4.23431C5.34634 3.9219 5.85288 3.9219 6.1653 4.23431C6.47772 4.54673 6.47772 5.05327 6.1653 5.36569L4.33098 7.2L13.5996 7.2C14.0414 7.2 14.3996 7.55817 14.3996 8C14.3996 8.44183 14.0414 8.8 13.5996 8.8L4.33098 8.8L6.1653 10.6343C6.47772 10.9467 6.47772 11.4533 6.1653 11.7657Z"
        fill="currentColor"
      />
    </svg>
  );
}
