import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';

export const UploadProgressBar = ({ progress }: { progress: number }) => {
  const cappedProgress = Math.min(progress, 100);

  const data = [
    { name: 'Progress', value: cappedProgress },
    { name: 'Remaining', value: 100 - cappedProgress },
  ];

  const COLORS = ['#ff6a33', '#f0f0f0'];

  return (
    <div style={{ position: 'relative', width: 140, height: 140 }}>
      <PieChart width={140} height={140}>
        <Pie
          data={data}
          innerRadius={60}
          outerRadius={70}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
          paddingAngle={0}
          cornerRadius={10}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          fontSize: '20px',
          fontWeight: '700',
          color: '#ff6a33',
        }}
      >
        {`${cappedProgress}%`}
      </div>
    </div>
  );
};

export default UploadProgressBar;
