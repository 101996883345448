import { useCallback, useMemo, useState } from 'react';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { useModal } from 'hooks/useModal';
import PlusPlain from 'svg/PlusPlain';
import Pill from 'components/Pill';
import AddSkillModal from './AddSkillModal';
import { SkillsProps } from './types';
import { getLessonSkills, toggleSkillSelection } from './utils';

const Skills = <T extends FieldValues>({
  control,
  label = true,
  fieldName = 'skills',
  allowCustom = true,
}: SkillsProps<T>) => {
  const {
    isOpen: isAddSkillModalOpen,
    openModal: openAddSkillModal,
    closeModal: closeAddSkillModal,
  } = useModal();

  const [customSkills, setCustomSkills] = useState<Array<string>>([]);

  const lessonSkills = useMemo(() => getLessonSkills(customSkills), [customSkills]);

  const handleAddSkill = useCallback((newSkill: string) => {
    setCustomSkills((curr) => [...curr, newSkill]);
  }, []);

  return (
    <>
      <div className="">
        <p className="typography-product-subheading text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
          Which skill are you focused on?
        </p>

        <Controller
          name={fieldName as Path<T>}
          control={control}
          render={({ field, formState: { errors } }) => (
            <div className="mt-4">
              <div className="flex flex-wrap items-start gap-2">
                {lessonSkills.map((lessonSkill) => (
                  <Pill
                    key={lessonSkill.value}
                    text={lessonSkill.label}
                    selected={field.value?.includes(lessonSkill.value)}
                    onClick={() =>
                      field.onChange(toggleSkillSelection(field.value || [], lessonSkill.value))
                    }
                    mode="select"
                  />
                ))}

                {allowCustom && (
                  <Pill
                    text="Custom"
                    onClick={() => openAddSkillModal()}
                    mode="select"
                    icon={<PlusPlain className="h-4 w-4 scale-125" />}
                  />
                )}
              </div>

              {errors?.[fieldName]?.message && typeof errors?.[fieldName]?.message === 'string' && (
                <p className="mt-2 text-xs text-color-error">{errors?.[fieldName].message}</p>
              )}
            </div>
          )}
        />
      </div>
      <AddSkillModal
        isOpen={isAddSkillModalOpen}
        handleAddSkill={handleAddSkill}
        handleClose={() => closeAddSkillModal()}
      />
    </>
  );
};

export default Skills;
