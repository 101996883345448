import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export const useMobileView = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); // 640px is the breakpoint for `sm` in Tailwind
    };

    const debouncedHandleResize = debounce(handleResize, 100); // Debounce resizing logic

    window.addEventListener('resize', debouncedHandleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  return isMobile;
};
