const ENFORCE_XHR = true;

/**
 * Uploads file to Cloudflare R2 bucket with progress tracking.
 * @returns http status
 */
export const uploadVideoFile = async ({
  file,
  signedUrl,
  onProgress,
}: {
  file: File;
  signedUrl: string;
  onProgress?: (progressEvent: ProgressEvent) => void;
}) => {
  // Check if browser supports required APIs
  const supportsStreams = !!(
    typeof ReadableStream !== 'undefined' &&
    // @ts-expect-error
    file.stream &&
    window.WritableStream
  );

  if (supportsStreams && !ENFORCE_XHR) {
    return uploadWithStreams({ file, signedUrl, onProgress });
  } else {
    return uploadWithXHR({ file, signedUrl, onProgress });
  }
};

const uploadWithStreams = async ({
  file,
  signedUrl,
  onProgress,
}: {
  file: File;
  signedUrl: string;
  onProgress?: (progressEvent: ProgressEvent) => void;
}) => {
  return new Promise<number>(async (resolve, reject) => {
    try {
      const stream = file.stream();
      const reader = stream.getReader();
      let bytesUploaded = 0;

      const progressStream = new ReadableStream({
        async pull(controller) {
          const { done, value } = await reader.read();

          if (done) {
            controller.close();
            return;
          }

          bytesUploaded += value.length;

          if (onProgress) {
            onProgress(
              new ProgressEvent('progress', {
                lengthComputable: true,
                loaded: bytesUploaded,
                total: file.size,
              }),
            );
          }

          controller.enqueue(value);
        },
      });

      const response = await fetch(signedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: progressStream,
      });

      if (response.ok) {
        resolve(response.status);
      } else {
        reject(new Error(`Failed to upload file. Status: ${response.status}`));
      }
    } catch (error) {
      reject(new Error('Upload failed.'));
    }
  });
};

const uploadWithXHR = ({
  file,
  signedUrl,
  onProgress,
}: {
  file: File;
  signedUrl: string;
  onProgress?: (progressEvent: ProgressEvent) => void;
}) => {
  return new Promise<number>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    if (onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.status);
      } else {
        reject(new Error(`Failed to upload file. Status: ${xhr.status}`));
      }
    };

    xhr.onerror = () => reject(new Error('Upload failed.'));
    xhr.open('PUT', signedUrl);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });
};
