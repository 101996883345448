import React from 'react';
import ArrowLeft from 'svg/ArrowLeft';

type HeaderProps = {
  title: string;
  showBack?: boolean;
  showBackAction?: () => void;
  cta?: string;
};

const Header: React.FC<HeaderProps> = ({ title, showBack, showBackAction, cta }) => {
  return (
    <div className="flex flex-col gap-6">
      <h2 className="typography-product-heading-mobile flex flex-row items-center gap-4 text-color-text-brand dark:text-color-text-brand">
        {showBack && (
          <button className="w-fit flex-shrink-0 p-0" onClick={showBackAction}>
            <ArrowLeft className="h-5 w-5 text-color-text-brand dark:text-color-text-brand" />
          </button>
        )}
        {title}
      </h2>
      {!!cta && (
        <p className="typography-product-subheading pb-6 text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
          {cta}
        </p>
      )}
    </div>
  );
};

export default Header;
