import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { VenueAddress } from 'utils/shared/coachBuilder';
import { calculateHaversineDistance } from 'utils/shared/geo/calculateHaversineDistance';
import { pluralize } from 'utils/shared/pluralize';
import { useGeoLocation } from 'hooks/useGeoLocation';
import Calendar from 'svg/Calendar';
import Check from 'svg/Check';
import ChevronLeft from 'svg/ChevronLeft';
import ChevronRight from 'svg/ChevronRight';
import Home from 'svg/Home';
import Button from 'components/Button';
import classNames from 'styles/utils/classNames';

enum PickingStates {
  OnDatePicking = 'ON_DATE_PICKING',
  OnTimePicking = 'ON_TIME_PICKING',
}

interface VenueSliderProps {
  setPickingState: React.Dispatch<React.SetStateAction<PickingStates | null>>;
  venues: VenueAddress[];
  selectedVenues: Map<string, VenueAddress> | null;
  setSelectedVenues: React.Dispatch<React.SetStateAction<Map<string, VenueAddress>>>;
  setVenueFromMap?: React.Dispatch<React.SetStateAction<VenueAddress | null>>;
}

interface VenueCardProps {
  venue: VenueAddress;
  distance: number;
  isSelected: boolean;
  customCourtImgPlaceholderSrc: string;
  handleVenueSelection: (venue: VenueAddress) => () => void;
}

const CUSTOM_COURT_IMG_PLACEHOLDER_SRC = '/images/coaches/only-address-venue.png';

export default function VenueSlider({
  setPickingState,
  venues,
  setSelectedVenues,
  selectedVenues,
  setVenueFromMap,
}: VenueSliderProps) {
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const { centerLatitude, centerLongitude } = useGeoLocation();

  useEffect(() => {
    if (sliderRef.current) {
      const handleScroll = () => {
        if (sliderRef.current) {
          setIsAtStart(sliderRef.current.scrollLeft === 0);
          setIsAtEnd(
            sliderRef.current.scrollLeft + sliderRef.current.clientWidth >=
              sliderRef.current.scrollWidth,
          );
        }
      };

      sliderRef.current.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check

      return () => {
        if (sliderRef.current) {
          sliderRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, []);

  const backToDatePicker = () => {
    setVenueFromMap?.(null);
    setPickingState(PickingStates.OnDatePicking);
  };

  const getIsVenueSelected = (venue: VenueAddress): boolean => !!selectedVenues?.get(venue.id);

  const handleVenueSelection = (venue: VenueAddress) => () => {
    setSelectedVenues((prevSelectedVenues) => {
      const selectedVenuesCopy = new Map(prevSelectedVenues);
      if (selectedVenuesCopy.has(venue.id)) {
        selectedVenuesCopy.delete(venue.id);
      } else {
        selectedVenuesCopy.set(venue.id, venue);
      }
      return selectedVenuesCopy;
    });
  };

  const handleLeftClick = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -sliderRef.current.clientWidth, behavior: 'smooth' });
    }
  };

  const handleRightClick = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: sliderRef.current.clientWidth, behavior: 'smooth' });
    }
  };

  return (
    <div className="mb-8">
      <div className="mb-3 flex items-center justify-between gap-1 px-6">
        <h1 className="typography-product-heading-compact-desktop text-color-brand-primary">
          Book a Lesson
        </h1>
        <span onClick={backToDatePicker} className="cursor-pointer">
          <Calendar className="h-6 w-6 text-color-brand-primary max-lg:mr-6" />
        </span>
      </div>
      <div className="mb-2 flex justify-end gap-7 px-6">
        <Button
          onClick={handleLeftClick}
          variant="inverted"
          className={classNames('border-none', isAtStart && 'cursor-not-allowed opacity-50')}
          isInline
          disabled={isAtStart}
          iconLeft={<ChevronLeft className="h-6 w-6 cursor-pointer" />}
        />
        <Button
          onClick={handleRightClick}
          variant="inverted"
          className={classNames('border-none', isAtEnd && 'cursor-not-allowed opacity-50')}
          isInline
          disabled={isAtEnd}
          iconLeft={<ChevronRight className="h-6 w-6 cursor-pointer" />}
        />
      </div>
      <div className="flex gap-4 overflow-x-hidden px-6 py-1" ref={sliderRef}>
        {venues.map((venue) => {
          const isSelected = getIsVenueSelected(venue);
          return (
            <VenueCard
              key={venue.id}
              isSelected={isSelected}
              handleVenueSelection={handleVenueSelection}
              venue={venue}
              customCourtImgPlaceholderSrc={CUSTOM_COURT_IMG_PLACEHOLDER_SRC}
              distance={Math.round(
                calculateHaversineDistance({
                  coord1: {
                    latitude: venue.latitude || 0,
                    longitude: venue.longitude || 0,
                  },
                  coord2: {
                    latitude: centerLatitude || 0,
                    longitude: centerLongitude || 0,
                  },
                  unit: 'miles',
                }),
              )}
            />
          );
        })}
      </div>
    </div>
  );
}

const VenueCard: React.FC<VenueCardProps> = ({
  customCourtImgPlaceholderSrc,
  venue,
  isSelected,
  handleVenueSelection,
  distance,
}) => {
  return (
    <div
      className={classNames(
        'relative flex h-full min-h-40 w-[calc(50%-24px)] shrink-0 cursor-pointer select-none flex-col overflow-hidden rounded-md text-color-text-lightmode-primary shadow-color-bg-lightmode-invert outline outline-2 outline-offset-2 transition-all dark:text-color-text-darkmode-primary dark:shadow-color-bg-darkmode-invert',
        isSelected &&
          'outline-color-border-input-darkmode dark:outline-color-border-input-lightmode',
        !isSelected && 'outline-transparent',
      )}
      onClick={handleVenueSelection(venue)}
    >
      <div
        className={classNames(
          'absolute right-3 top-3 z-10 rounded-full bg-color-bg-lightmode-primary p-1 opacity-0 transition-opacity dark:bg-color-bg-darkmode-primary',
          isSelected && 'opacity-100',
        )}
      >
        <Check className="h-5 w-5" />
      </div>
      <div className="relative h-16 overflow-hidden rounded-tl-md rounded-tr-md">
        <Image
          src={venue?.images?.[0] || customCourtImgPlaceholderSrc}
          alt="venue-image"
          fill={true}
        />
      </div>
      <div className="flex flex-1 flex-col gap-1 rounded-bl-md rounded-br-md bg-color-bg-lightmode-secondary p-2 dark:bg-color-bg-darkmode-secondary">
        <span className="typography-product-button-label-xs">{venue.title || venue.address}</span>
        <div className="typography-product-text-card mt-auto flex gap-1">
          <Home className="h-[0.875rem] w-[0.875rem] text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder" />
          <span>{venue?.indoorCourtCount ? 'Indoor' : 'Outdoor'}</span>
          <span className="text-color-text-lightmode-placeholder dark:text-color-text-darkmode-placeholder">
            &bull;
          </span>
          <span>
            {pluralize({
              count: distance,
              singular: 'mile',
              plural: 'miles',
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
