import { MediaUploadResponse } from 'constants/media';

export interface PostRequestPayload {
  fileName: string;
  fileType: string;
}

export interface PostResponsePayload extends MediaUploadResponse {
  signedUrl: string;
}

export const FILE_SIGN_API = '/v1/files/sign';
