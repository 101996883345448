import { format } from 'date-fns';
import { VenueAddress } from 'utils/shared/coachBuilder';
import { dateAsTimezoneFormatted } from 'utils/shared/date/dateAsTimezone';

export interface timeSlot {
  time: string;
  venue: VenueAddress | null;
}

export function generateTimeSlots(
  startHour: number,
  endHour: number,
  interval: number,
  dateFormat: string,
  timezone = 'America/New_York',
  bookingInMinutes: number = 60,
  venue: VenueAddress | null = null,
): timeSlot[] {
  const timeSlots: timeSlot[] = [];
  const startMinutes = (startHour % 1) * 60;
  const endMinutes = (endHour % 1) * 60;
  const fullStartHour = Math.floor(startHour);
  const fullEndHour = Math.floor(endHour);

  const totalEndTimeMinutes = fullEndHour * 60 + endMinutes;

  for (let hour = fullStartHour; hour <= fullEndHour; hour++) {
    const startMin = hour === fullStartHour ? startMinutes : 0;

    for (let minutes = startMin; minutes < 60; minutes += interval) {
      const slotStartMinutes = hour * 60 + minutes;
      const slotEndMinutes = slotStartMinutes + bookingInMinutes;

      if (slotEndMinutes <= totalEndTimeMinutes) {
        const date = new Date();
        date.setHours(hour, minutes, 0, 0);
        timeSlots.push({
          time: format(dateAsTimezoneFormatted(date, timezone), dateFormat),
          venue,
        });
      }
    }
  }

  return timeSlots;
}
