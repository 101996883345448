import React, { createContext, useContext, useState } from 'react';

interface UploadContextType {
  uploadProgress: number | null;
  errorUpload: string | null;
  setUploadProgress: (progress: number | null) => void;
  setErrorUpload: (error: string | null) => void;
}

const UploadContext = createContext<UploadContextType | undefined>(undefined);

export const UploadProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [errorUpload, setErrorUpload] = useState<string | null>(null);

  return (
    <UploadContext.Provider value={{ uploadProgress, errorUpload, setUploadProgress, setErrorUpload }}>
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadContext = (): UploadContextType => {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error('useUploadContext must be used within an UploadProvider');
  }
  return context;
};
