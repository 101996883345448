export interface PostRequestPayload {
  coachId: string;
  paymentMethodId: string;
  amount: number;
  date: string;
  startTime: string;
  endTime: string;
  locale: string;
  timezoneName: string;
  timezoneAbbreviation: string;
  timezoneOffsetMinutes: number;
  venueId: string;
  userCustomCourtId: string;
  fullAddress?: string;
}

export const COACH_PAYMENT_API = `v1/lessons/book`;
