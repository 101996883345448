export default function PlusPlain({
  className,
  viewBox = '0 0 20 20',
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="none"
      className={className}
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 5a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H6a1 1 0 1 1 0-2h3V6a1 1 0 0 1 1-1Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
