import { FormEventHandler } from 'react';
import Button from 'components/Button';
import Modal from 'components/modals/Modal';
import { AddSkillModalProps } from './types';

const AddSkillModal: React.FC<AddSkillModalProps> = ({ isOpen, handleClose, handleAddSkill }) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = new FormData(event.currentTarget);
    const skill = form.get('skill') as string | null;

    if (skill && skill.trim()) {
      handleAddSkill(skill.trim());
      handleClose();
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <form onSubmit={handleSubmit} className="flex flex-col px-4 py-5">
        <label
          htmlFor="skill-input"
          className="typography-product-subheading text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary"
        >
          Add Custom Skill
        </label>
        <input
          name="skill"
          id="skill-input"
          placeholder="Skill"
          className="mt-3 rounded-md border-0 bg-brand-gray-50 pl-3 pr-3 font-light text-color-text-lightmode-primary focus:outline-0 dark:text-color-text-darkmode-primary"
        />
        <Button variant="primary" size="lg" type="submit" className="mt-5">
          Submit
        </Button>
      </form>
    </Modal>
  );
};

export default AddSkillModal;
