export const toFormattedPhone = (phoneNumber: string): string => {
    let inputPhoneNumber: string = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    // Apply formatting based on the length of the input
    if (inputPhoneNumber.length > 3 && inputPhoneNumber.length <= 6) {
        inputPhoneNumber = `(${inputPhoneNumber.slice(0, 3)})-${inputPhoneNumber.slice(3)}`;
    } else if (inputPhoneNumber.length > 6) {
        inputPhoneNumber = `(${inputPhoneNumber.slice(0, 3)})-${inputPhoneNumber.slice(
            3,
            6,
        )}-${inputPhoneNumber.slice(6)}`;
    }

    return inputPhoneNumber;
};
