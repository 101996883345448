import * as React from 'react';

export default function Alert({
  className,
  viewBox = '0 0 144 131',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon picture">
        <ellipse
          id="Ellipse 756"
          opacity="0.5"
          cx="60"
          cy="111.404"
          rx="60"
          ry="8.5919"
          fill="url(#paint0_radial_15780_84782)"
        />
        <path
          id="Icon"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M60.8498 4.68707C64.3651 -1.56236 73.3629 -1.56236 76.8782 4.68707L102.534 50.297C105.982 56.4264 101.552 64 94.5196 64H43.2084C36.1758 64 31.7464 56.4264 35.1943 50.297L60.8498 4.68707Z"
          fill="url(#paint1_radial_15780_84782)"
        />
        <path
          id="Icon_2"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M52.8498 18.6871C56.3651 12.4376 65.3629 12.4376 68.8782 18.6871L94.5337 64.297C97.9816 70.4264 93.5522 78 86.5196 78H35.2084C28.1758 78 23.7464 70.4264 27.1943 64.297L52.8498 18.6871Z"
          fill="url(#paint2_radial_15780_84782)"
        />
        <rect id="Rectangle 4811" x="45" y="42" width="15" height="45" fill="white" />
        <path
          id="Icon_3"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.8498 34.6871C47.3651 28.4376 56.3629 28.4376 59.8782 34.6871L85.5337 80.297C88.9816 86.4264 84.5522 94 77.5196 94H26.2084C19.1758 94 14.7464 86.4264 18.1943 80.297L43.8498 34.6871ZM56.4611 80.208C56.4611 82.7471 54.4027 84.8055 51.8636 84.8055C49.3245 84.8055 47.2661 82.7471 47.2661 80.208C47.2661 77.6688 49.3245 75.6104 51.8636 75.6104C54.4027 75.6104 56.4611 77.6688 56.4611 80.208ZM51.8636 43.4278C49.3245 43.4278 47.2661 45.4862 47.2661 48.0253V61.8179C47.2661 64.357 49.3245 66.4154 51.8636 66.4154C54.4027 66.4154 56.4611 64.357 56.4611 61.8179V48.0253C56.4611 45.4862 54.4027 43.4278 51.8636 43.4278Z"
          fill="#FF5C33"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_15780_84782"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(60 111.404) rotate(90) scale(8.5919 60)"
        >
          <stop stop-color="#7C7C7C" stop-opacity="0.4" />
          <stop offset="0.838555" stop-color="#747474" stop-opacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_15780_84782"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(68.864 48.32) rotate(-90) scale(47.0115 51.219)"
        >
          <stop stop-color="#FF5C33" stop-opacity="0.4" />
          <stop offset="1" stop-color="#FF5C33" stop-opacity="0.1" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_15780_84782"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(60.864 62.32) rotate(-90) scale(47.0115 51.219)"
        >
          <stop stop-color="#FF5C33" stop-opacity="0.7" />
          <stop offset="1" stop-color="#FF5C33" stop-opacity="0.2" />
        </radialGradient>
      </defs>
    </svg>
  );
}
