import { addHours, format, parse } from 'date-fns';

export function addOneHour(time: string): string {
  const dateFormat = 'hh:mm a';

  // Parse the time string into a Date object
  const date = parse(time, dateFormat, new Date());

  // Add 1 hour to the parsed date
  const newDate = addHours(date, 1);

  // Format the new date back to a readable time string
  const newTimeString = format(newDate, dateFormat);

  return newTimeString;
}

export function getDateOnly(date: Date): string {
  // Format the date as 'yyyy-MM-dd' to get only the date part
  return format(date, 'yyyy-MM-dd');
}

function convertTo24HourFormat(time: string): string {
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours.padStart(2, '0')}:${minutes}`;
}

export function convertToISOStrings(
  date: string,
  startTime: string,
  endTime: string,
): { startDateTime: string; endDateTime: string } {
  const startDateTimeString = `${date}T${convertTo24HourFormat(startTime)}:00`;
  const endDateTimeString = `${date}T${convertTo24HourFormat(endTime)}:00`;

  const startDateTime = new Date(startDateTimeString).toISOString();
  const endDateTime = new Date(endDateTimeString).toISOString();

  return { startDateTime, endDateTime };
}
