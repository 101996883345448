import classNames from 'styles/utils/classNames';

interface NoDataPlaceholderProps {
  text: string | React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
}

const NoDataPlaceholder: React.FC<NoDataPlaceholderProps> = ({
  text,
  footer = <></>,
  className,
}) => {
  return (
    <div
      className={classNames(
        'flex h-60 flex-col justify-center rounded-xl bg-color-bg-lightmode-secondary px-4 text-center dark:bg-color-bg-darkmode-secondary',
        className,
      )}
    >
      <span className="typography-product-text-card text-color-text-lightmode-tertiary dark:text-color-text-darkmode-tertiary">
        {text}
      </span>
      {footer}
    </div>
  );
};

export default NoDataPlaceholder;
