export const toSentenceCase = (str: string): string => {
  // Split the string by hyphens or underscores
  const words = str?.split(/[-_]/);

  // Capitalize the first letter of each word and ensure the rest of the word is lowercase
  const convertedStr = words
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return convertedStr || str;
};
