import * as React from 'react';

const AddPhotoV2 = ({ className, viewBox }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox || '0 0 32 32'} className={className}>
      <path
        fill="currentColor"
        d="M28.0267 6.66602H25.3333V3.97268C25.3333 3.25268 24.7467 2.66602 24.0267 2.66602H23.9867C23.2533 2.66602 22.6667 3.25268 22.6667 3.97268V6.66602H19.9867C19.2667 6.66602 18.68 7.25268 18.6667 7.97268V8.01268C18.6667 8.74602 19.2533 9.33268 19.9867 9.33268H22.6667V12.0127C22.6667 12.7327 23.2533 13.3327 23.9867 13.3193H24.0267C24.7467 13.3193 25.3333 12.7327 25.3333 12.0127V9.33268H28.0267C28.7467 9.33268 29.3333 8.74602 29.3333 8.02602V7.97268C29.3333 7.25268 28.7467 6.66602 28.0267 6.66602ZM21.3333 12.0127V10.666H19.9867C19.28 10.666 18.6133 10.386 18.1067 9.89268C17.6133 9.38602 17.3333 8.71935 17.3333 7.97268C17.3333 7.49268 17.4667 7.05268 17.6933 6.66602H6.66667C5.2 6.66602 4 7.86602 4 9.33268V25.3327C4 26.7993 5.2 27.9993 6.66667 27.9993H22.6667C24.1333 27.9993 25.3333 26.7993 25.3333 25.3327V14.2927C24.9333 14.5194 24.48 14.666 23.9733 14.666C22.52 14.6527 21.3333 13.466 21.3333 12.0127ZM21.28 25.3327H8C7.45333 25.3327 7.13333 24.706 7.46667 24.266L10.1067 20.7593C10.3867 20.386 10.9333 20.4127 11.2 20.786L13.3333 23.9993L16.8133 19.3594C17.08 19.0127 17.6 18.9993 17.8667 19.346L21.8 24.2527C22.1467 24.6927 21.84 25.3327 21.28 25.3327Z"
      />
    </svg>
  );
};

export default AddPhotoV2;
