import { VideoAnalysisSkillsCommentsInsertInput } from 'types/generated/client';
import { videoFileType } from 'screens/CoachDetails/types';

export interface PostVideoRequestPayload {
  coachId: string;
  paymentMethodId: string;
  amount: number;
  locale: string;
  timezoneName: string;
  timezoneAbbreviation: string;
  timezoneOffsetMinutes: number;
  videoAnalysisFiles?: videoFileType[];
  requestedFeedback: string;
  skills?: string[];
}

export const VIDEO_ANALYSIS_REMOTE_BOOK_API = `v1/lessons/remote/book`;
