import React from 'react';
import Head from 'next/head';

export default function PopppinsFont() {
  return (
    <Head>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap"
        rel="stylesheet"
      />
    </Head>
  );
}
